













































import { Component, Ref, Vue } from "vue-property-decorator";
import { NotificationState } from "@/store/modules/notification";
import pointRateService from "@/service/pointRateService";
import PointRateEditDialog from "@/components/organisms/point_rate/PointRateEditDialog.vue";
import PointRateDeleteDialog from "@/components/organisms/point_rate/PointRateDeleteDialog.vue";
import { PointRate } from "@/graphql/client";

@Component({ components: { PointRateEditDialog, PointRateDeleteDialog } })
export default class PointRateList extends Vue {
  @Ref() readonly editDialog!: PointRateEditDialog;
  @Ref() readonly deleteDialog!: PointRateDeleteDialog;
  //---------------------------
  // data
  //---------------------------
  items: PointRate[] = [];
  headers = [
    {
      text: "名称",
      value: "name",
    },
    {
      text: "金額（下限）",
      value: "lowerLimit",
      width: "15%",
    },
    {
      text: "金額（上限）",
      value: "upperLimit",
      width: "15%",
    },
    {
      text: "割引率",
      value: "rate",
      width: "10%",
    },
    {
      text: "表示順",
      value: "displayOrder",
      width: "10%",
    },
    {
      text: "",
      value: "editAction",
      width: "30px",
      sortable: false,
    },
    {
      text: "",
      value: "deleteAction",
      width: "30px",
      sortable: false,
    },
  ];

  loadingDataGrid = false;

  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }

  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async fetchData(): Promise<void> {
    this.loadingDataGrid = true;
    pointRateService.allPointRates().then((items) => {
      this.items = items;
      this.loadingDataGrid = false;
    });
  }
  /**
   * ポイント付与率編集ダイアログ（新規追加）を表示します.
   */
  public openCreateDialog(): void {
    this.editDialog.open(pointRateService.defaultPointRate);
  }

  /**
   * ポイント付与率編集ダイアログを表示します.
   */
  public openEditDialog(item: PointRate): void {
    this.editDialog.open(item);
  }

  /**
   * ポイント付与率編集ダイアログの更新成功時.
   */
  public onUpdateSuccess(updated: PointRate): void {
    //一覧にも反映
    const index = this.items.findIndex((el) => {
      return el.guid === updated.guid;
    });
    if (index > -1) {
      console.log("UPDATE SUCCES");
      this.items.splice(index, 1, updated);
    }

    this.editDialog.close();
    NotificationState.notifySuccess("ポイント付与率を更新しました！");
  }

  /**
   * ポイント付与率編集ダイアログの登録成功時.
   */
  public onCreateSuccess(created: PointRate): void {
    //一覧にも追加
    this.items.unshift(created);

    this.editDialog.close();
    NotificationState.notifySuccess("ポイント付与率を登録しました！");
  }

  /**
   * ポイント付与率削除ダイアログを表示します.
   */
  public openDeleteDialog(item: PointRate): void {
    this.deleteDialog.open(Object.assign({}, item));
  }

  /**
   * ポイント付与率削除ダイアログの処理成功時.
   */
  public onDeleteSuccess(deleted: PointRate): void {
    //一覧からも削除
    const newItems = this.items.filter((item) => item.guid !== deleted.guid);
    this.items = newItems;

    this.deleteDialog.close();
    NotificationState.notifySuccess("ポイント付与率を削除しました！");
  }
}
