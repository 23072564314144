import { PointRate } from "@/graphql/client";
import { sdk } from "@/plugins/graphql-provider";

/**
 * ポイント付与率に関する機能を提供します.
 */
class PointRateService {
  /**
   * 初期状態の ポイント付与率
   */
  public get defaultPointRate(): PointRate {
    return {
      guid: "",
      name: "",
      displayOrder: 0,
      rate: 0,
      lowerLimit: 0,
      upperLimit: 0,
      createdAt: "",
      updatedAt: "",
    } as PointRate;
  }

  /**
   * すべてのジャンプアップポイント付与率を取得して返します.
   * @returns すべてのジャンプアップポイント付与率
   */
  public async allPointRates() {
    const response = await sdk.pointRates();
    if (response?.pointRates) {
      return response.pointRates as PointRate[];
    }
    return [] as PointRate[];
  }

  /**
   * 指定したGUIDに一致するジャンプアップポイント付与率を取得して返します.
   * @param guid ジャンプアップポイント付与率のGUID
   * @returns ジャンプアップポイント付与率
   */
  public async getPointRate(guid: string) {
    const response = await sdk.pointRate({
      pointRateGuid: guid,
    });
    return response.pointRate as PointRate;
  }

  /**
   * ジャンプアップポイント付与率を登録します.
   * @param input ジャンプアップポイント付与率の登録情報
   * @returns 登録完了後のジャンプアップポイント付与率
   */
  public async createPointRate(input: PointRate) {
    try {
      const response = await sdk.createPointRate({
        createPointRateInput: {
          name: input.name,
          lowerLimit: input.lowerLimit,
          upperLimit: input.upperLimit,
          rate: input.rate,
          displayOrder: input.displayOrder,
        },
      });

      if (response?.createPointRate) {
        return response.createPointRate;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * ジャンプアップポイント付与率を更新します.
   * @param input ジャンプアップポイント付与率の更新情報
   * @returns 更新完了後のジャンプアップポイント付与率
   */
  public async updatePointRate(input: PointRate) {
    try {
      const response = await sdk.updatePointRate({
        updatePointRateInput: {
          guid: input.guid,
          name: input.name,
          lowerLimit: input.lowerLimit,
          upperLimit: input.upperLimit,
          rate: input.rate,
          displayOrder: input.displayOrder,
        },
      });

      if (response?.updatePointRate) {
        return response.updatePointRate;
      }
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * ジャンプアップポイント付与率を削除します.
   * @param input ジャンプアップポイント付与率の削除情報
   * @returns 削除したジャンプアップポイント付与率
   */
  public async deletePointRate(input: PointRate) {
    try {
      const response = await sdk.deletePointRate({
        deletePointRateInput: {
          guid: input.guid,
        },
      });

      if (response?.deletePointRate) {
        return response.deletePointRate;
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new PointRateService();
