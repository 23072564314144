










import SubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import PointRateList from "@/components/organisms/point_rate/PointRateList.vue";

export default {
  components: {
    SubPageTemplate,
    PointRateList,
  },
};
